import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const ContactPageTemplate = ({
  title,
  subtitle,
  meta_title,
  meta_description,
}) => {
  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section className='hero is-primary'>
        <div className='hero-body subpage'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10'>
                <h2 className='is-size-2 anthracite-70-text capitalize page-heading'>
                  {title}
                </h2>
              </div>
              <div className='column is-2 anthracite-70-text text-center breadcrumb'>
                <span>{'HOME > Kontakt'}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='yellow-border' />
      <section className='section'>
        <div className='container'>
          <div className='content'>
            <div className='anthracite-text '>
              {subtitle}
            </div>
            <br />
            <span>Ihr Bernd Bechert</span>
            <br />
            <br />
            <div className='columns'>
              <div className='column is-6'>
                <div className='contact-form'>
                  <iframe src='/webform.php' style={{ width: '100%', height: '1200px', border: 0 }} scrolling='no' marginHeight='0' marginWidth='0' />
                </div>
              </div>
              <div className='column is-6'>
                <div className='iframe'>
                  <iframe width='100%' height='650' frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0' src='https://www.openstreetmap.org/export/embed.html?bbox=13.45904588699341%2C52.60927637001298%2C13.488700389862062%2C52.6249487889095&amp;layer=mapnik&amp;marker=52.617113280784146%2C13.473873138427734' /><br /><small><a href='https://www.openstreetmap.org/?mlat=52.6171&amp;mlon=13.4739#map=16/52.6171/13.4739' target='_blank' rel='noopener noreferrer'>Größere Karte anzeigen</a></small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='spacer' />
    </div>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default ContactPageTemplate
